import React, { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getBackgroundColor } from '../../utils';
import { Button } from '../button';
import { Account, ChevronLeft, ChevronRight, Close } from '../icon';
import { LinkLabel } from '../linkLabel';
import { Spacer } from '../spacer/Spacer';
import { Typography } from '../typography/Typography';

// Interface

export interface NavigationProps {
  isActive?: boolean;
  setNavOpen: boolean;
  items: [];
}

type NavigationItemsProps = NavigationProps;

export interface NavigationListProps {
  links: NavigationListItemProps[];
  variant?: string;
  showSubNavigation: number;
  handleShowSubNavigation: () => void;
  setNavOpen: boolean;
  setShowSubNav: () => void;
  showSubNav: number | null;
}

export interface NavigationListItemProps {
  url?: string;
  label?: string;
  variant?: string;
  items?: [];
  topLevel?: boolean;
  links: NavigationListItemProps[];
  link: { linkName: string; url?: string };
  handleShowSubNavigation: () => void;
  setNavOpen: boolean;
  title?: string;
  childNavigation?: [];
}

export interface StyledNavPanelProps {
  isActive: boolean;
}

export interface StyledNavListItemProps {
  isTopLevel?: boolean;
  hasChildren?: boolean;
  showSubNav: number | null;
}

export interface StyledLinkLabelProps {
  isTopLevel?: boolean;
  rotate?: boolean;
  id: string;
}

const StyledNavPanel = styled.div<StyledNavPanelProps>`
  ${({ theme: { layers, media }, isActive }) => css`
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
    overflow: auto;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: ${layers.navigation};
    ${getBackgroundColor('neutralWhite')};

    ${isActive &&
    css`
      transform: translateX(0%);
      display: block;
      width: 100vw;
      height: 100vh;
    `};

    @media (min-width: ${media.large}) {
      width: 100vw;
      padding: 0;
      transform: translateX(0%);
      transform: none;
      transition: transform 0;
      height: auto;
      ${getBackgroundColor('transparent')};
      overflow: visible;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `};
`;

const StyledSubNavSpacer = styled.div`
  ${({ theme: { media, space, colors } }) => css`
    padding-bottom: 100px;

    & li:first-of-type {
      @media (min-width: ${media.large}) {
        height: unset;
      }
    }

    @media (min-width: ${media.large}) {
      ${getBackgroundColor('neutralWhite')};
      padding: ${space.small};

      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      li {
        border: solid 1px ${colors.neutralLightGrey};
        border-bottom: none;

        &:nth-of-type(3n) {
          border-right: none;
        }

        &:nth-of-type(1n) {
          border-left: none;
        }

        &:nth-of-type(-n + 3) {
          border-top: none;
        }
      }
    }
  `};
`;

const StyledNavigationTitle = styled.div`
  ${({ theme: { space, layers, media } }) => css`
    ${getBackgroundColor('neutralGrey')};
    position: relative;
    z-index: ${layers.navigationTitle}; // less than the control button, more than the container
    width: 100%;
    padding: ${space.small};
    text-align: center;

    @media (min-width: ${media.large}) {
      display: none;
    }
  `};
`;

const StyledSubMenuTitle = styled(Typography)`
  ${({ theme: { space, media } }) => css`
    padding: ${space.small};

    @media (min-width: ${media.large}) {
      display: none;
      flex-grow: 1;
      text-align: left;
    }
  `};
`;

const StyledNavListItem = styled.li<StyledNavListItemProps>`
  ${({
    theme: { space, media, colors },
    isTopLevel,
    hasChildren,
    showSubNav,
  }) => css`
    border-bottom: solid 1px;
    margin-top: 0 !important;

    @media (min-width: ${media.large}) {
      border-bottom: none;
      width: auto;
      display: inline-block;
      display: unset;
      padding: 18px 0;
      flex-grow: 1;
      justify-content: flex-start;
      padding-left: ${space.xxSmall};

      ${!isTopLevel &&
      css`
        color: initial;
        display: inline-block;
        padding-left: ${space.small};
      `}

      .subNav {
        // sub menu
        ${getBackgroundColor('neutralWhite')}
        color: initial
        display: none;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: row;
      }

      .subNav-${showSubNav} {
        display: flex;
      }

      ${isTopLevel &&
      hasChildren &&
      css`
        padding: 44px ${space.xxSmall};

        :hover {
          button:before {
            bottom: -42px;
            left: 50%;
            border: 6px solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent transparent ${colors.neutralWhite};
            margin-left: -5px;
            z-index: 11;
          }

          button:after {
            bottom: -41px;
            left: 50%;
            border: 6px solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent transparent ${colors.neutralMidGrey};
            margin-left: -5px;
          }
        }
      `}
    }
  `};
`;

const StyledLinkLabel = styled(LinkLabel)<StyledLinkLabelProps>`
  ${({ theme: { space, media, colors }, isTopLevel, rotate }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${space.small};
    height: 80px;

    @media (min-width: ${media.large}) {
      width: auto;
      height: auto;
      display: inline-flex;
      color: initial;
      text-align: left;

      ${isTopLevel &&
      css`
        padding-right: 0;
        padding: 0;
      `};

      ${!isTopLevel &&
      css`
        z-index: 15;
        display: block;
      `};

      ${!isTopLevel &&
      css`
        z-index: 15;
        display: block;
        padding: 0;
      `};

      ${rotate &&
      css`
        & svg {
          transform: rotate(90deg);
        }
      `}

      +  .subNav {
        display: none;
        width: 80vw;
        margin: auto;
        top: 106px;
        position: absolute;
        left: -25%;
        border: solid 1px ${colors.neutralMidGrey};
        max-width: 1050px;
      }

      ${isTopLevel &&
      css`
        :hover {
          color: ${colors.brandOne100};
          text-decoration-color: ${colors.brandOne100};
        }
      `}
    }

    @media (min-width: ${media.xLarge}) {
      + .subNav {
        left: -31%;
      }
    }
  `};
`;

const StyledCloseSubButton = styled(Button)`
  ${({ theme: { space, layers } }) => css`
    position: absolute;
    top: ${space.small};
    left: ${space.small};
    z-index: ${layers.navShowHideButton};

    &:hover {
      background: none;
    }
  `};
`;

const StyledChevronLeft = styled(ChevronLeft)`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandOne100};
  `};
`;

const StyledChevronRight = styled(ChevronRight)`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandOne100};
  `};
`;

const StyledAccount = styled(Account)`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandOne100};
    width: 24px;
    height: 24px;
  `};
`;

const TopNavWrapper = styled.div`
  text-align: right;
`;

const StyledClosedButton = styled(Button)`
  ${({ theme: { colors, space, layers, media } }) => css`
    position: absolute;
    top: ${space.small};
    right: ${space.small};
    z-index: ${layers.navShowHideButton};
    min-width: 24px;

    &:hover {
      background-color: transparent;
      color: ${colors.brandOne100};
    }

    @media (min-width: ${media.large}) {
      display: none;
    }
  `};
`;

const NavigationTopLevel: FC<NavigationListProps> = ({
  links,
  variant,
  showSubNavigation,
  handleShowSubNavigation,
  setNavOpen,
  setShowSubNav,
  showSubNav,
  ...rest
}) => {
  if (!links || links.length <= 0) {
    return null;
  }

  return (
    <Spacer as="ul" spacing="small" {...rest}>
      {links?.length >= 1 &&
        links.map(({ link, childNavigation, topLevel = true }, index) => {
          const hasChildren = childNavigation && childNavigation.length > 0;

          return (
            <StyledNavListItem
              key={`sub-menu-${index}`}
              isTopLevel={topLevel}
              hasChildren={hasChildren}
              showSubNav={showSubNav}
              onMouseEnter={() => setShowSubNav(index)}
              onMouseLeave={() => setShowSubNav(null)}
            >
              {/* Top level nav */}
              <StyledLinkLabel
                iconRight={
                  (hasChildren && <StyledChevronRight size="small" />) ||
                  (link.linkName === 'Account' && <StyledAccount />)
                }
                url={link.linkName === 'Account' ? link.url : undefined}
                label={link.linkName}
                isTopLevel={topLevel}
                rotate={hasChildren}
                id={`top-nav-${index}`}
                onClick={() => handleShowSubNavigation(index)}
              />

              {hasChildren && (
                <StyledNavPanel
                  isActive={showSubNavigation === index}
                  role="tabpanel"
                  className={`subNav subNav-${index}`}
                >
                  <NavigationListItem
                    links={childNavigation}
                    handleShowSubNavigation={handleShowSubNavigation}
                    setNavOpen={setNavOpen}
                    title={link.linkName}
                    onMouseEnter={() => setShowSubNav(index)}
                    onMouseLeave={() => setShowSubNav(null)}
                    setShowSubNav={setShowSubNav}
                  />
                </StyledNavPanel>
              )}
            </StyledNavListItem>
          );
        })}
    </Spacer>
  );
};

const NavigationListItem: FC<NavigationListItemProps> = ({
  links,
  handleShowSubNavigation,
  setNavOpen,
  title,
  topLevel,
  setShowSubNav,
  ...rest
}) => {
  if (!links || links.length <= 0) {
    return null;
  }

  return (
    <>
      <StyledSubMenuTitle component="h4" variant="bodyLarge">
        {title}
      </StyledSubMenuTitle>
      <StyledSubNavSpacer as="ul" spacing="medium" {...rest}>
        {links?.length >= 1 &&
          links.map(({ link }, index) => (
            <StyledNavListItem key={index} topLevel={topLevel}>
              {/* Sub nav list */}
              <StyledLinkLabel
                url={link?.url}
                label={link?.linkName}
                onClick={() => {
                  handleShowSubNavigation(-1);
                  setNavOpen(false);
                  setShowSubNav(null);
                }}
              />
            </StyledNavListItem>
          ))}
      </StyledSubNavSpacer>
    </>
  );
};

const NavigationItems: FC<NavigationItemsProps> = ({
  items,
  isActive,
  setNavOpen,
}) => {
  const [showSubNavigation, setShowSubNavigation] = useState(-1);
  const [navigationTitle, setNavigationTitle] = useState('Menu');
  const [showSubNav, setShowSubNav] = useState<null | number>(null);

  useEffect(() => {
    if (!isActive) setShowSubNavigation(-1);
  }, [isActive]);

  const handleShowSubNavigation = (index: number): void => {
    setShowSubNavigation(index);
    const title = items[index]?.link?.linkName;
    setNavigationTitle(title ? String(title) : 'Menu');
  };

  return (
    <StyledNavPanel isActive={isActive}>
      <TopNavWrapper>
        <StyledNavigationTitle>
          {/* Menu back button */}

          {showSubNavigation >= 0 && (
            <StyledCloseSubButton
              a11yTitle="navigation back"
              variant="secondaryInverse"
              small
              iconLeft={<StyledChevronLeft size="small" />}
              onClick={() => handleShowSubNavigation(-1)}
            />
          )}
          {navigationTitle}

          <StyledClosedButton
            variant="primaryInverse"
            iconRight={<Close size="small" />}
            a11yTitle="Close"
            onClick={() => {
              setNavOpen(false);
            }}
            small
          />
        </StyledNavigationTitle>
        <nav>
          <NavigationTopLevel
            links={items}
            showSubNavigation={showSubNavigation}
            handleShowSubNavigation={handleShowSubNavigation}
            setNavOpen={setNavOpen}
            setShowSubNav={setShowSubNav}
            showSubNav={showSubNav}
          />
        </nav>
      </TopNavWrapper>
    </StyledNavPanel>
  );
};

export const Navigation: FC<NavigationProps> = ({
  items,
  isActive,
  setNavOpen,
}) => (
  <NavigationItems items={items} isActive={isActive} setNavOpen={setNavOpen} />
);

export default Navigation;
